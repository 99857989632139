<template>
    <div class="c-live-room">
        <iframe id="iframe" :src="`https://playtequan.haoyouzhushou.com:6561/?phone=${phone}&url=${url}`" width="100%"
            height="716px" frameborder="0"></iframe>
        <router-link class="return" to="/index">返回</router-link>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["isLogin", "userInfo", "netBarId", "netBarName"]),
    },
    data() {
        return {
            phone: "",
            url: ""
            // url: "https://down.ggzuhao.com/shipinglubo/0000.mkv"
        }
    },
    created() {
        if (this.userInfo) {
            this.phone = this.userInfo.Phone
        }
        if (this.$route.query && this.$route.query.url) {
            this.url = decodeURIComponent(this.$route.query.url);
        }
    }
}
</script>

<style scoped lang="scss">
.c-live-room {
    position: relative;

    .return {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 14px;
        padding: 8px 20px;
        background: #ff3636;
        color: #fff;
        border-radius: 4px;
    }
}
</style>