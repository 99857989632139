<template>
    <div class="live-room-wrap">
        <CLiveRoom />
    </div>
</template>

<script>
import CLiveRoom from "@/views/components/CLiveRoom.vue"
export default {
    name: "Home",
    components: {
        CLiveRoom
    },
}
</script>

<style scoped lang="scss">
.live-room-wrap {
    // background: red;
}
</style>